/* eslint-disable max-lines */
import has from '@tinkoff/utils/object/has';

import {batchActions} from 'utils/batch_actions';
import {
    ChannelTypes,
    EmojiTypes,
    GroupTypes,
    PostTypes,
    TeamTypes,
    UserTypes,
    RoleTypes,
    GeneralTypes,
    AdminTypes,
    IntegrationTypes,
    PreferenceTypes,
    AppsTypes,
    FileTypes,
} from 'mattermost-redux/action_types';
import {WebsocketEvents, General, Permissions, Preferences} from 'mattermost-redux/constants';
import {fetchMyCategories, receivedCategoryOrder} from 'mattermost-redux/actions/channel_categories';
import {
    getChannelAndMyMember,
    getMyChannelMember,
    getChannelStats,
    markChannelAsRead,
} from 'mattermost-redux/actions/channels';
import {getCloudSubscription} from 'mattermost-redux/actions/cloud';
import {loadRolesIfNeeded} from 'mattermost-redux/actions/roles';

import {getBool} from 'mattermost-redux/selectors/entities/preferences';

import {setServerVersion, getClientConfig} from 'mattermost-redux/actions/general';
import {
    getCustomEmojiForReaction,
    getPosts,
    getPostThread,
    getProfilesAndStatusesForPosts,
    getThreadsForPosts,
    receivedNewPost,
} from 'mattermost-redux/actions/posts';
import {clearErrors, logError} from 'mattermost-redux/actions/errors';

import * as TeamActions from 'mattermost-redux/actions/teams';
import {checkForModifiedUsers, getStatusesByIds, getUser as loadUser} from 'mattermost-redux/actions/users';
import {removeNotVisibleUsers} from 'mattermost-redux/actions/websocket';

import {Client4} from 'mattermost-redux/client';
import {
    getCurrentUser,
    getCurrentUserId,
    getStatusForUserId,
    getUser,
    getIsManualStatusForUserId,
    isCurrentUserSystemAdmin,
    getUsers,
} from 'mattermost-redux/selectors/entities/users';
import {
    getMyTeams,
    getCurrentRelativeTeamUrl,
    getCurrentTeamId,
    getCurrentTeamUrl,
    getTeam,
    getCurrentTeam,
} from 'mattermost-redux/selectors/entities/teams';
import {getConfig, getLicense, isPerformanceDebuggingEnabled} from 'mattermost-redux/selectors/entities/general';
import {
    getChannel,
    getChannelMembersInChannels,
    getChannelsInTeam,
    getCurrentChannel,
    getCurrentChannelId,
    getRedirectChannelNameForTeam,
} from 'mattermost-redux/selectors/entities/channels';
import {getPost, getMostRecentPostIdInChannel} from 'mattermost-redux/selectors/entities/posts';
import {haveISystemPermission, haveITeamPermission} from 'mattermost-redux/selectors/entities/roles';
import {appsFeatureFlagEnabled} from 'mattermost-redux/selectors/entities/apps';
import {getStandardAnalytics} from 'mattermost-redux/actions/admin';

import {fetchAppBindings, fetchRHSAppsBindings} from 'mattermost-redux/actions/apps';

import {getSelectedChannelId, getSelectedPost, getSelectedPostId} from 'selectors/rhs';

import {openModal} from 'actions/views/modals';
import {incrementWsErrorCount, resetWsErrorCount} from 'actions/views/system';
import {closeRightHandSide} from 'actions/views/rhs';
import {syncPostsInChannel} from 'actions/views/channel';

import {browserHistory} from 'utils/browser_history';
import {loadChannelsForCurrentUser} from 'actions/channel_actions';
import {redirectToSelfDirectChannel} from 'actions/global_actions';
import {handleNewPost} from 'actions/post_actions';
import * as StatusActions from 'actions/status_actions';
import store from 'stores/redux_store';
import WebSocketClient from 'client/web_websocket_client';
import {loadPlugin, loadPluginsIfNecessary, removePlugin} from 'plugins';
import {
    ActionTypes,
    Constants,
    AnnouncementBarMessages,
    SocketEvents,
    UserStatuses,
    ModalIdentifiers,
    WarnMetricTypes,
} from 'utils/constants';
import {getSiteURL} from 'utils/url';
import {isGuestPlus} from 'mattermost-redux/utils/user_utils';
import RemovedFromChannelModal from 'components/removed_from_channel_modal';
import InteractiveDialog from 'components/interactive_dialog';

import {getDmUsers} from 'mattermost-redux/selectors/entities/getDmUsers';
import {addChannelToInitialCategory, handleDirectOrGroupChannelAddedEvent} from 'features/sidebar';
import {receivedCurrentUser, receivedUser} from 'features/users';

import {handleNewPostEventWithQueue} from 'features/posts';

import {isFeatureEnabled} from 'utils/utils';

import {reportErrorToSentry} from 'utils/sentry';

import {selectThreadById} from 'features/threads/selectors/select_thread_by_id';
import {selectLatestThreadInTeam} from 'features/threads/selectors/select_latest_thread_in_team';
import {getHasUnreadThreadsByTeamId} from 'features/threads/actions/get_has_unread_threads_by_team_id';
import {handleThreadUpdated} from 'features/threads/ws_event_handlers/handle_thread_updated';
import {handleThreadReadChanged} from 'features/threads/ws_event_handlers/handle_thread_read_changed';
import {handleThreadFollowChanged} from 'features/threads/ws_event_handlers/handle_thread_follow_changed';
import {getSelectedThreadId} from 'features/threads/selectors/get_selected_thread_id';

import {TiMeLogger} from 'features/logger';

import {handlePreferencesChangedEvent as handlePreferencesChangedForSidebar} from 'features/sidebar/ws_event_handlers/handle_preferences_changed';
import {handlePreferenceChangedEvent as handlePreferenceChangedForSidebar} from 'features/sidebar/ws_event_handlers/handle_preference_changed';

import {handleSidebarCategoryUpdatedEvent} from 'features/sidebar/ws_event_handlers/handle_sidebar_category_updated';

import {handleSidebarCategoryCreatedEvent} from 'features/sidebar/ws_event_handlers/handle_sidebar_category_created';
import {isAppFocused} from 'features/app_activity/selector/is_app_focused';

import {handlePostDeleteEvent, handlePostedEventDebounced, handlePostEditedEvent} from 'features/websockets/actions';
import {sendReactionNotification} from 'features/notifications/actions/send_reaction_notification';

import {deleteActivityReaction} from 'features/activity/actions';

import {fetchUsersByIds} from 'features/users/actions/fetch_users_by_ids';

import {redirectUserToDefaultTeam} from './global_actions';

import {loadCustomEmojisIfNeeded} from './loadCustomEmojisIfNeeded';

const dispatch = store.dispatch;
const getState = store.getState;

const MAX_WEBSOCKET_FAILS = 7;

const pluginEventHandlers = {};

export function initialize() {
    if (!window.WebSocket) {
        console.log('Browser does not support websocket'); //eslint-disable-line no-console
        return;
    }

    const config = getConfig(getState());
    let connUrl = '';
    if (config.WebsocketURL) {
        connUrl = config.WebsocketURL;
    } else {
        connUrl = new URL(getSiteURL());

        // replace the protocol with a websocket one
        if (connUrl.protocol === 'https:') {
            connUrl.protocol = 'wss:';
        } else {
            connUrl.protocol = 'ws:';
        }

        // append a port number if one isn't already specified
        if (!(/:\d+$/).test(connUrl.host)) {
            if (connUrl.protocol === 'wss:') {
                connUrl.host += ':' + config.WebsocketSecurePort;
            } else {
                connUrl.host += ':' + config.WebsocketPort;
            }
        }

        connUrl = connUrl.toString();
    }

    // Strip any trailing slash before appending the pathname below.
    if (connUrl.length > 0 && connUrl[connUrl.length - 1] === '/') {
        connUrl = connUrl.substring(0, connUrl.length - 1);
    }

    // eslint-disable-next-line no-process-env
    if (process.env.IS_DEV) {
        // eslint-disable-next-line no-process-env
        connUrl = `ws://${window.location.hostname}:${process.env.DEV_PORT}`;
    }

    connUrl += Client4.getUrlVersion() + '/websocket';

    WebSocketClient.setEventCallback(handleEvent);
    WebSocketClient.setFirstConnectCallback(handleFirstConnect);
    WebSocketClient.setReconnectCallback(() => {
        reconnect(false, false);
        syncPresence();
    });
    WebSocketClient.setMissedEventCallback(() => restart(true));
    WebSocketClient.setCloseCallback(handleClose);
    WebSocketClient.initialize(connUrl);
}

export function close() {
    WebSocketClient.close();
}

function reconnectWebSocket() {
    close();
    initialize();
}

const pluginReconnectHandlers = {};

export function registerPluginReconnectHandler(pluginId, handler) {
    pluginReconnectHandlers[pluginId] = handler;
}

export function unregisterPluginReconnectHandler(pluginId) {
    Reflect.deleteProperty(pluginReconnectHandlers, pluginId);
}

function restart(includeSync = false) {
    reconnect(false, includeSync);

    // We fetch the client config again on the server restart.
    dispatch(getClientConfig());
}

export function reconnect(includeWebSocket = true, includeSync = false) {
    if (includeWebSocket) {
        reconnectWebSocket();
    }

    dispatch({
        type: GeneralTypes.WEBSOCKET_SUCCESS,
        timestamp: Date.now(),
    });

    if (includeSync) {
        sync();
    }

    dispatch(resetWsErrorCount());
    dispatch(clearErrors());
}

function sync() {
    const state = getState();
    const currentTeamId = getCurrentTeamId(state);

    if (currentTeamId) {
        const currentChannelId = getCurrentChannelId(state);
        const mostRecentId = getMostRecentPostIdInChannel(state, currentChannelId);
        const mostRecentPost = getPost(state, mostRecentId);

        if (appsFeatureFlagEnabled(state)) {
            dispatch(handleRefreshAppsBindings());
        }

        dispatch(loadChannelsForCurrentUser());

        if (mostRecentPost) {
            dispatch(syncPostsInChannel(currentChannelId, mostRecentPost.create_at));
        } else if (currentChannelId) {
            // if network timed-out the first time when loading a channel
            // we can request for getPosts again when socket is connected
            dispatch(getPosts(currentChannelId));
        }
        StatusActions.loadStatusesForChannelAndSidebar();

        dispatch(TeamActions.getMyTeamUnreads(true, true));

        const teams = getMyTeams(state);
        syncThreads(currentTeamId);

        for (const team of teams) {
            if (team.id === currentTeamId) {
                continue;
            }
            syncThreads(team.id);
        }

        // Есть открытый тред: в глобальном разделе или в канале
        const selectedThreadId = getSelectedThreadId(state) || getSelectedPostId(state);
        if (selectedThreadId) {
            try {
                dispatch(getPostThread(selectedThreadId));
            } catch (e) {
                //@TODO: мы не ожидаем здесь ошибки, поэтому в будущем можно убрать try-catch,
                // если не будет ошибок в ErrorHub
                reportErrorToSentry(e);
            }
        }
    }

    loadPluginsIfNecessary();

    Object.values(pluginReconnectHandlers).forEach((handler) => {
        if (handler && typeof handler === 'function') {
            handler();
        }
    });

    if (state.websocket.lastDisconnectAt) {
        dispatch(checkForModifiedUsers());
    }
}

function syncThreads(teamId) {
    const state = getState();
    const newestThread = selectLatestThreadInTeam(state, teamId);

    // no need to sync if we have nothing yet
    if (!newestThread) {
        return;
    }

    dispatch(
        getHasUnreadThreadsByTeamId({
            teamId,
        }),
    );
}

function syncPresence() {
    const state = getState();
    const config = getConfig(state);

    if (config.FeatureFlagWebSocketTypingEventScoping === 'true') {
        const channelId = getCurrentChannelId(state);
        const threadId = getSelectedPostId(state) || getSelectedThreadId(state);

        WebSocketClient.updatePresence({channelId, threadId});
    }
}

let intervalId = '';
const SYNC_INTERVAL_MILLISECONDS = 1000 * 60 * 15; // 15 minutes

export function startPeriodicSync() {
    clearInterval(intervalId);

    intervalId = setInterval(() => {
        if (getCurrentUser(getState()) != null) {
            reconnect(false, true);
        }
    }, SYNC_INTERVAL_MILLISECONDS);
}

export function stopPeriodicSync() {
    clearInterval(intervalId);
}

export function registerPluginWebSocketEvent(pluginId, event, action) {
    if (!pluginEventHandlers[pluginId]) {
        pluginEventHandlers[pluginId] = {};
    }
    pluginEventHandlers[pluginId][event] = action;
}

export function unregisterPluginWebSocketEvent(pluginId, event) {
    const events = pluginEventHandlers[pluginId];
    if (!events) {
        return;
    }

    Reflect.deleteProperty(events, event);
}

export function unregisterAllPluginWebSocketEvents(pluginId) {
    Reflect.deleteProperty(pluginEventHandlers, pluginId);
}

function handleFirstConnect() {
    dispatch(
        batchActions([
            {
                type: GeneralTypes.WEBSOCKET_SUCCESS,
                timestamp: Date.now(),
            },
            clearErrors(),
        ]),
    );
}

function handleClose(failCount) {
    if (failCount > MAX_WEBSOCKET_FAILS) {
        dispatch(logError({type: 'critical', message: AnnouncementBarMessages.WEBSOCKET_PORT_ERROR}, true));
    }
    dispatch(
        batchActions([
            {
                type: GeneralTypes.WEBSOCKET_FAILURE,
                timestamp: Date.now(),
            },
            incrementWsErrorCount(),
        ]),
    );
}

function handlePostedEvent(msg) {
    const state = getState();

    const isQueuedPostsEnabled = isFeatureEnabled(Constants.PRE_RELEASE_FEATURES.POST_QUEQUE, state);

    if (isQueuedPostsEnabled) {
        dispatch(handleNewPostEventWithQueue(msg));
    } else {
        handlePostedEventDebounced(msg);
    }
}
const wsLogger = TiMeLogger.child({name: 'websocket'});

export function handleEvent(msg) {
    wsLogger.debug(msg);
    switch (msg.event) {
    case SocketEvents.POSTED:
        handlePostedEvent(msg);
        break;
    case SocketEvents.EPHEMERAL_MESSAGE:
        handleNewPostEventDebounced(msg);
        break;

    case SocketEvents.POST_EDITED:
        handlePostEditedEvent(msg);
        break;

    case SocketEvents.POST_DELETED:
        handlePostDeleteEvent(msg);
        break;

    case SocketEvents.POST_UNREAD:
        handlePostUnreadEvent(msg);
        break;

    case SocketEvents.LEAVE_TEAM:
        handleLeaveTeamEvent(msg);
        break;

    case SocketEvents.UPDATE_TEAM:
        handleUpdateTeamEvent(msg);
        break;

    case SocketEvents.UPDATE_TEAM_SCHEME:
        handleUpdateTeamSchemeEvent(msg);
        break;

    case SocketEvents.DELETE_TEAM:
        handleDeleteTeamEvent(msg);
        break;

    case SocketEvents.ADDED_TO_TEAM:
        dispatch(handleAddedToTeamEvent(msg));
        break;

    case SocketEvents.USER_ADDED:
        dispatch(handleUserAddedEvent(msg));
        break;

    case SocketEvents.USER_REMOVED:
        handleUserRemovedEvent(msg);
        break;

    case SocketEvents.USER_UPDATED:
        handleUserUpdatedEvent(msg);
        break;

    case SocketEvents.USER_TERMS_UPDATED:
        handleUserTermsUpdatedEvent(msg);
        break;

    case SocketEvents.ROLE_ADDED:
        handleRoleAddedEvent(msg);
        break;

    case SocketEvents.ROLE_REMOVED:
        handleRoleRemovedEvent(msg);
        break;

    case SocketEvents.CHANNEL_SCHEME_UPDATED:
        handleChannelSchemeUpdatedEvent(msg);
        break;

    case SocketEvents.MEMBERROLE_UPDATED:
        handleUpdateMemberRoleEvent(msg);
        break;

    case SocketEvents.ROLE_UPDATED:
        handleRoleUpdatedEvent(msg);
        break;

    case SocketEvents.CHANNEL_CREATED:
        dispatch(handleChannelCreatedEvent(msg));
        break;

    case SocketEvents.CHANNEL_DELETED:
        handleChannelDeletedEvent(msg);
        break;

    case SocketEvents.CHANNEL_UNARCHIVED:
        handleChannelUnarchivedEvent(msg);
        break;

    case SocketEvents.CHANNEL_CONVERTED:
        handleChannelConvertedEvent(msg);
        break;

    case SocketEvents.CHANNEL_UPDATED:
        dispatch(handleChannelUpdatedEvent(msg));
        break;

    case SocketEvents.CHANNEL_MEMBER_UPDATED:
        handleChannelMemberUpdatedEvent(msg);
        break;

    case SocketEvents.DIRECT_ADDED:
        dispatch(handleDirectOrGroupChannelAddedEvent(msg));
        break;

    case SocketEvents.GROUP_ADDED:
        dispatch(handleDirectOrGroupChannelAddedEvent(msg));
        break;

    case SocketEvents.NEED_SIGN_TERMS_OF_SERVICE:
        handleConfigTermsOfServiceIdChanged(msg);
        break;

    case SocketEvents.PREFERENCE_CHANGED:
        handlePreferenceChangedEvent(msg);
        break;

    case SocketEvents.PREFERENCES_CHANGED:
        handlePreferencesChangedEvent(msg);
        break;

    case SocketEvents.PREFERENCES_DELETED:
        handlePreferencesDeletedEvent(msg);
        break;

    case SocketEvents.TYPING:
        dispatch(handleUserTypingEvent(msg));
        break;

    case SocketEvents.STATUS_CHANGED:
        handleStatusChangedEvent(msg);
        break;

    case SocketEvents.HELLO:
        handleHelloEvent(msg);
        break;

    case SocketEvents.REACTION_ADDED:
        handleReactionAddedEvent(msg);
        break;

    case SocketEvents.REACTION_REMOVED:
        handleReactionRemovedEvent(msg);
        break;

    case SocketEvents.EMOJI_ADDED:
        handleAddEmoji(msg);
        break;

    case SocketEvents.CHANNEL_VIEWED:
        handleChannelViewedEvent(msg);
        break;

    case SocketEvents.PLUGIN_ENABLED:
        handlePluginEnabled(msg);
        break;

    case SocketEvents.PLUGIN_DISABLED:
        handlePluginDisabled(msg);
        break;

    case SocketEvents.USER_ROLE_UPDATED:
        handleUserRoleUpdated(msg);
        break;

    case SocketEvents.CONFIG_CHANGED:
        handleConfigChanged(msg);
        break;

    case SocketEvents.LICENSE_CHANGED:
        handleLicenseChanged(msg);
        break;

    case SocketEvents.PLUGIN_STATUSES_CHANGED:
        handlePluginStatusesChangedEvent(msg);
        break;

    case SocketEvents.OPEN_DIALOG:
        handleOpenDialogEvent(msg);
        break;

    case SocketEvents.RECEIVED_GROUP:
        handleGroupUpdatedEvent(msg);
        break;

    case SocketEvents.GROUP_MEMBER_ADD:
        dispatch(handleGroupAddedMemberEvent(msg));
        break;

    case SocketEvents.GROUP_MEMBER_DELETED:
        dispatch(handleGroupDeletedMemberEvent(msg));
        break;

    case SocketEvents.RECEIVED_GROUP_ASSOCIATED_TO_TEAM:
        handleGroupAssociatedToTeamEvent(msg);
        break;

    case SocketEvents.RECEIVED_GROUP_NOT_ASSOCIATED_TO_TEAM:
        handleGroupNotAssociatedToTeamEvent(msg);
        break;

    case SocketEvents.RECEIVED_GROUP_ASSOCIATED_TO_CHANNEL:
        handleGroupAssociatedToChannelEvent(msg);
        break;

    case SocketEvents.RECEIVED_GROUP_NOT_ASSOCIATED_TO_CHANNEL:
        handleGroupNotAssociatedToChannelEvent(msg);
        break;

    case SocketEvents.WARN_METRIC_STATUS_RECEIVED:
        handleWarnMetricStatusReceivedEvent(msg);
        break;

    case SocketEvents.WARN_METRIC_STATUS_REMOVED:
        handleWarnMetricStatusRemovedEvent(msg);
        break;

    case SocketEvents.SIDEBAR_CATEGORY_CREATED:
        dispatch(handleSidebarCategoryCreatedEvent(msg));
        break;

    case SocketEvents.SIDEBAR_CATEGORY_UPDATED:
        dispatch(handleSidebarCategoryUpdatedEvent(msg));
        break;

    case SocketEvents.SIDEBAR_CATEGORY_DELETED:
        dispatch(handleSidebarCategoryDeleted(msg));
        break;
    case SocketEvents.SIDEBAR_CATEGORY_ORDER_UPDATED:
        dispatch(handleSidebarCategoryOrderUpdated(msg));
        break;
    case SocketEvents.USER_ACTIVATION_STATUS_CHANGED:
        dispatch(handleUserActivationStatusChange());
        break;
    case SocketEvents.CLOUD_PAYMENT_STATUS_UPDATED:
        dispatch(handleCloudPaymentStatusUpdated(msg));
        break;
    case SocketEvents.FIRST_ADMIN_VISIT_MARKETPLACE_STATUS_RECEIVED:
        handleFirstAdminVisitMarketplaceStatusReceivedEvent(msg);
        break;
    case SocketEvents.THREAD_FOLLOW_CHANGED:
        dispatch(handleThreadFollowChanged(msg));
        break;
    case SocketEvents.THREAD_READ_CHANGED:
        dispatch(handleThreadReadChanged(msg));
        break;
    case SocketEvents.THREAD_UPDATED:
        dispatch(handleThreadUpdated(msg));
        break;
    case SocketEvents.APPS_FRAMEWORK_REFRESH_BINDINGS:
        dispatch(handleRefreshAppsBindings());
        break;
    case SocketEvents.APPS_FRAMEWORK_PLUGIN_ENABLED:
        dispatch(handleAppsPluginEnabled());
        break;
    case SocketEvents.APPS_FRAMEWORK_PLUGIN_DISABLED:
        dispatch(handleAppsPluginDisabled());
        break;
    case SocketEvents.FILE_DELETED:
        dispatch(handleFileDeletedEvent(msg));
        break;
    default:
    }

    Object.values(pluginEventHandlers).forEach((pluginEvents) => {
        if (!pluginEvents) {
            return;
        }

        if (has(msg.event, pluginEvents) && typeof pluginEvents[msg.event] === 'function') {
            pluginEvents[msg.event](msg);
        }
    });
}

// handleChannelConvertedEvent handles updating of channel which is converted from public to private
function handleChannelConvertedEvent(msg) {
    const channelId = msg.data.channel_id;
    if (channelId) {
        const channel = getChannel(getState(), channelId);
        if (channel) {
            dispatch({
                type: ChannelTypes.RECEIVED_CHANNEL,
                data: {...channel, type: General.PRIVATE_CHANNEL},
            });
        }
    }
}

export function handleChannelUpdatedEvent(msg) {
    return (doDispatch, doGetState) => {
        const channel = JSON.parse(msg.data.channel);

        doDispatch({type: ChannelTypes.RECEIVED_CHANNEL, data: channel});

        const state = doGetState();
        if (channel.id === getCurrentChannelId(state)) {
            browserHistory.replace(`${getCurrentRelativeTeamUrl(state)}/channels/${channel.name}`);
        }
    };
}

function handleChannelMemberUpdatedEvent(msg) {
    const channelMember = JSON.parse(msg.data.channelMember);
    const roles = channelMember.roles.split(' ');
    dispatch(loadRolesIfNeeded(roles));
    dispatch({type: ChannelTypes.RECEIVED_MY_CHANNEL_MEMBER, data: channelMember});
}

function debouncePostEvent(wait) {
    let timeout;
    let queue = [];
    let count = 0;

    // Called when timeout triggered
    const triggered = () => {
        timeout = null;

        if (queue.length > 0) {
            dispatch(handleNewPostEvents(queue));
        }

        queue = [];
        count = 0;
    };

    return function fx(msg) {
        if (timeout && count > 4) {
            // If the timeout is going this is the second or further event so queue them up.
            if (queue.push(msg) > 200) {
                // Don't run us out of memory, give up if the queue gets insane
                queue = [];
                console.log('channel broken because of too many incoming messages'); //eslint-disable-line no-console
            }
            clearTimeout(timeout);
            timeout = setTimeout(triggered, wait);
        } else {
            // Apply immediately for events up until count reaches limit
            count += 1;
            dispatch(handleNewPostEvent(msg));
            clearTimeout(timeout);
            timeout = setTimeout(triggered, wait);
        }
    };
}

const POST_DEBOUNCE_TIMEOUT = 500;

const handleNewPostEventDebounced = debouncePostEvent(POST_DEBOUNCE_TIMEOUT);

export function handleNewPostEvent(msg) {
    return (myDispatch, myGetState) => {
        const post = JSON.parse(msg.data.post);
        myDispatch(handleNewPost(post, msg));

        getProfilesAndStatusesForPosts([post], myDispatch, myGetState);

        // Since status updates aren't real time, assume another user is online if they have posted and:
        // 1. The user hasn't set their status manually to something that isn't online
        // 2. The server hasn't told the client not to set the user to online. This happens when:
        //     a. The post is from the auto responder
        //     b. The post is a response to a push notification
        if (
            post.user_id !== getCurrentUserId(myGetState()) &&
            !getIsManualStatusForUserId(myGetState(), post.user_id) &&
            msg.data.set_online
        ) {
            myDispatch({
                type: UserTypes.RECEIVED_STATUSES,
                data: [{user_id: post.user_id, status: UserStatuses.ONLINE}],
            });
        }
    };
}

export function handleNewPostEvents(queue) {
    return (myDispatch, myGetState) => {
        // Note that this method doesn't properly update the sidebar state for these posts
        const posts = queue.map((msg) => JSON.parse(msg.data.post));

        // Receive the posts as one continuous block since they were received within a short period
        const actions = posts.map((post) => receivedNewPost(post, true));
        myDispatch(batchActions(actions));

        // Load the posts' threads
        myDispatch(getThreadsForPosts(posts));

        // And any other data needed for them
        getProfilesAndStatusesForPosts(posts, myDispatch, myGetState);
    };
}

export function handlePostUnreadEvent({data, broadcast}) {
    dispatch({
        type: ActionTypes.POST_UNREAD_SUCCESS,
        data: {
            ...data,
            channel_id: broadcast.channel_id,
        },
    });
}

export function handleAddedToTeamEvent(msg) {
    // eslint-disable-next-line no-shadow
    return async (dispatch, getState) => {
        const teamId = msg.data.team_id;
        const state = getState();
        const team = getTeam(state, teamId);

        if (team) {
            return;
        }

        await dispatch(TeamActions.getTeam(teamId));
        await dispatch(TeamActions.getMyTeamMembers());
        await dispatch(TeamActions.getMyTeamUnreads(true));
    };
}

export function handleLeaveTeamEvent(msg) {
    const state = getState();

    const actions = [
        {
            type: UserTypes.RECEIVED_PROFILE_NOT_IN_TEAM,
            data: {id: msg.data.team_id, user_id: msg.data.user_id},
        },
        {
            type: TeamTypes.REMOVE_MEMBER_FROM_TEAM,
            data: {team_id: msg.data.team_id, user_id: msg.data.user_id},
        },
    ];

    const channelsPerTeam = getChannelsInTeam(state);
    const channels = (channelsPerTeam && channelsPerTeam[msg.data.team_id]) || [];

    for (const channel of channels) {
        actions.push({
            type: ChannelTypes.REMOVE_MEMBER_FROM_CHANNEL,
            data: {id: channel, user_id: msg.data.user_id},
        });
    }

    dispatch(batchActions(actions));
    const currentUser = getCurrentUser(state);

    if (currentUser.id === msg.data.user_id) {
        dispatch({type: TeamTypes.LEAVE_TEAM, data: {id: msg.data.team_id}});

        // if they are on the team being removed redirect them to default team
        if (getCurrentTeamId(state) === msg.data.team_id) {
            if (!global.location.pathname.startsWith('/admin_console')) {
                redirectUserToDefaultTeam();
            }
        }
        if (isGuestPlus(currentUser.roles)) {
            dispatch(removeNotVisibleUsers());
        }
    } else {
        const team = getTeam(state, msg.data.team_id);
        const members = getChannelMembersInChannels(state);
        const isMember = Object.values(members).some((member) => member[msg.data.user_id]);
        if (team && isGuestPlus(currentUser.roles) && !isMember) {
            dispatch(
                batchActions([
                    {
                        type: UserTypes.PROFILE_NO_LONGER_VISIBLE,
                        data: {user_id: msg.data.user_id},
                    },
                    {
                        type: TeamTypes.REMOVE_MEMBER_FROM_TEAM,
                        data: {team_id: team.id, user_id: msg.data.user_id},
                    },
                ]),
            );
        }
    }
}

function handleUpdateTeamEvent(msg) {
    dispatch({type: TeamTypes.UPDATED_TEAM, data: JSON.parse(msg.data.team)});
}

function handleUpdateTeamSchemeEvent() {
    dispatch(TeamActions.getMyTeamMembers());
}

function handleDeleteTeamEvent(msg) {
    const deletedTeam = JSON.parse(msg.data.team);
    const state = store.getState();
    const {teams} = state.entities.teams;
    if (deletedTeam && teams && teams[deletedTeam.id] && teams[deletedTeam.id].delete_at === 0) {
        const {currentUserId} = state.entities.users;
        const {currentTeamId, myMembers} = state.entities.teams;
        const teamMembers = Object.values(myMembers);
        const teamMember = teamMembers.find((m) => m.user_id === currentUserId && m.team_id === currentTeamId);

        let newTeamId = '';
        if (deletedTeam && teamMember && deletedTeam.id === teamMember.team_id) {
            const myTeams = {};
            getMyTeams(state).forEach((t) => {
                myTeams[t.id] = t;
            });

            for (let i = 0; i < teamMembers.length; i++) {
                const memberTeamId = teamMembers[i].team_id;
                if (
                    myTeams &&
                    myTeams[memberTeamId] &&
                    myTeams[memberTeamId].delete_at === 0 &&
                    deletedTeam.id !== memberTeamId
                ) {
                    newTeamId = memberTeamId;
                    break;
                }
            }
        }

        dispatch(
            batchActions([
                {type: TeamTypes.RECEIVED_TEAM_DELETED, data: {id: deletedTeam.id}},
                {type: TeamTypes.UPDATED_TEAM, data: deletedTeam},
            ]),
        );

        if (browserHistory.location?.pathname === `/admin_console/user_management/teams/${deletedTeam.id}`) {
            return;
        }

        if (newTeamId) {
            dispatch({type: TeamTypes.SELECT_TEAM, data: newTeamId});
            const globalState = getState();
            const redirectChannel = getRedirectChannelNameForTeam(globalState, newTeamId);
            browserHistory.push(`${getCurrentTeamUrl(globalState)}/channels/${redirectChannel}`);
        } else {
            browserHistory.push('/');
        }
    }
}

function handleUpdateMemberRoleEvent(msg) {
    const memberData = JSON.parse(msg.data.member);
    const newRoles = memberData.roles.split(' ');

    dispatch(loadRolesIfNeeded(newRoles));

    dispatch({
        type: TeamTypes.RECEIVED_MY_TEAM_MEMBER,
        data: memberData,
    });
}

function handleUserAddedEvent(msg) {
    return async (doDispatch, doGetState) => {
        const state = doGetState();
        const license = getLicense(state);
        const currentChannelId = getCurrentChannelId(state);
        if (currentChannelId === msg.broadcast.channel_id) {
            doDispatch(getChannelStats(currentChannelId));
            doDispatch({
                type: UserTypes.RECEIVED_PROFILE_IN_CHANNEL,
                data: {id: msg.broadcast.channel_id, user_id: msg.data.user_id},
            });
        }

        // Load the channel so that it appears in the sidebar
        const currentTeamId = getCurrentTeamId(doGetState());
        const currentUserId = getCurrentUserId(doGetState());
        if (currentTeamId === msg.data.team_id && currentUserId === msg.data.user_id) {
            doDispatch(fetchChannelAndAddToSidebar(msg.broadcast.channel_id));
        }

        // This event is fired when a user first joins the server, so refresh analytics to see if we're now over the user limit
        if (license.Cloud === 'true' && isCurrentUserSystemAdmin(doGetState())) {
            doDispatch(getStandardAnalytics());
        }
    };
}

function fetchChannelAndAddToSidebar(channelId) {
    return async (doDispatch) => {
        const {data, error} = await doDispatch(getChannelAndMyMember(channelId));

        if (!error) {
            doDispatch(addChannelToInitialCategory({channel: data.channel, setOnServer: false}));
        }
    };
}

export function handleUserRemovedEvent(msg) {
    const state = getState();
    const currentChannel = getCurrentChannel(state);
    const currentUser = getCurrentUser(state);
    const currentTeam = getCurrentTeam(state);

    if (msg.broadcast.user_id === currentUser.id) {
        dispatch(loadChannelsForCurrentUser(msg.data.team_id));

        const rhsChannelId = getSelectedChannelId(state);
        if (msg.data.channel_id === rhsChannelId) {
            dispatch(closeRightHandSide());
        }

        if (msg.data.channel_id === currentChannel?.id) {
            if (msg.data.remover_id !== msg.broadcast.user_id) {
                const user = getUser(state, msg.data.remover_id);
                if (!user) {
                    dispatch(loadUser(msg.data.remover_id));
                }

                dispatch(
                    openModal({
                        modalId: ModalIdentifiers.REMOVED_FROM_CHANNEL,
                        dialogType: RemovedFromChannelModal,
                        dialogProps: {
                            channelName: currentChannel?.display_name,
                            removerId: msg.data.remover_id,
                        },
                    }),
                );
            }
        }

        const channel = getChannel(state, msg.data.channel_id);

        dispatch({
            type: ChannelTypes.LEAVE_CHANNEL,
            data: {
                id: msg.data.channel_id,
                user_id: msg.broadcast.user_id,
                team_id: channel?.team_id,
            },
        });

        if (!currentChannel || msg.data.channel_id === currentChannel.id) {
            if (currentTeam && currentUser) {
                redirectToSelfDirectChannel(currentTeam, currentUser);
            } else {
                redirectUserToDefaultTeam();
            }
        }

        if (isGuestPlus(currentUser.roles)) {
            dispatch(removeNotVisibleUsers());
        }
    } else if (msg.broadcast.channel_id === currentChannel?.id) {
        dispatch(getChannelStats(currentChannel?.id));
        dispatch({
            type: UserTypes.RECEIVED_PROFILE_NOT_IN_CHANNEL,
            data: {id: msg.broadcast.channel_id, user_id: msg.data.user_id},
        });
    }

    if (msg.broadcast.user_id !== currentUser.id) {
        const channel = getChannel(state, msg.broadcast.channel_id);
        const members = getChannelMembersInChannels(state);
        const isMember = Object.values(members).some((member) => member[msg.data.user_id]);
        if (channel && isGuestPlus(currentUser.roles) && !isMember) {
            const actions = [
                {
                    type: UserTypes.PROFILE_NO_LONGER_VISIBLE,
                    data: {user_id: msg.data.user_id},
                },
                {
                    type: TeamTypes.REMOVE_MEMBER_FROM_TEAM,
                    data: {team_id: channel.team_id, user_id: msg.data.user_id},
                },
            ];
            dispatch(batchActions(actions));
        }
    }

    const channelId = msg.broadcast.channel_id || msg.data.channel_id;
    const userId = msg.broadcast.user_id || msg.data.user_id;
    const channel = getChannel(state, channelId);
    if (
        channel &&
        !haveISystemPermission(state, {permission: Permissions.VIEW_MEMBERS}) &&
        !haveITeamPermission(state, channel.team_id, Permissions.VIEW_MEMBERS)
    ) {
        dispatch(
            batchActions([
                {
                    type: UserTypes.RECEIVED_PROFILE_NOT_IN_TEAM,
                    data: {id: channel.team_id, user_id: userId},
                },
                {
                    type: TeamTypes.REMOVE_MEMBER_FROM_TEAM,
                    data: {team_id: channel.team_id, user_id: userId},
                },
            ]),
        );
    }
}

export async function handleUserUpdatedEvent(msg) {
    // This websocket event is sent to all non-guest users on the server, so be careful requesting data from the server
    // in response to it. That can overwhelm the server if every connected user makes such a request at the same time.
    // See https://mattermost.atlassian.net/browse/MM-40050 for more information.

    const state = getState();
    const currentUser = getCurrentUser(state);
    const user = msg.data.user;
    const existsUsers = getUsers(state);

    // @see https://time-sentry.tinkoff.ru/organizations/sentry/issues/530/
    if (!user || !currentUser) {
        if (!user) {
            reportErrorToSentry('No user in user_updated');
        }
        if (!currentUser) {
            reportErrorToSentry('No current user in user_updated');
        }
        return;
    }

    if (!existsUsers?.[user.id]) {
        return;
    }

    if (user && user.props) {
        const customStatus = user.props.customStatus ? JSON.parse(user.props.customStatus) : undefined;
        dispatch(loadCustomEmojisIfNeeded([customStatus?.emoji]));
    }

    if (currentUser.id === user.id) {
        if (user.update_at > currentUser.update_at) {
            // update user to unsanitized user data recieved from websocket message
            dispatch(receivedCurrentUser(user));
            dispatch(loadRolesIfNeeded(user.roles.split(' ')));
        }
    } else {
        await dispatch(receivedUser(user));
    }
}

function handleUserTermsUpdatedEvent(msg) {
    dispatch({
        type: UserTypes.RECEIVED_TERMS_OF_SERVICE_STATUS,
        data: {
            user_id: msg.data.user_id,
            terms_of_service_id: msg.data.terms_of_service_id,
            terms_of_service_create_at: msg.data.terms_of_service_create_at,
        },
    });
}

function handleRoleAddedEvent(msg) {
    const role = JSON.parse(msg.data.role);

    dispatch({
        type: RoleTypes.RECEIVED_ROLE,
        data: role,
    });
}

function handleFileDeletedEvent(msg) {
    return (doDispatch) => {
        const fileId = msg.data.file_id;
        const postId = msg.data.post_id;

        doDispatch({
            type: postId ? FileTypes.REMOVED_FILE_FOR_POST : FileTypes.REMOVED_FILE,
            data: {
                fileId,
                postId,
            },
        });
    };
}

function handleRoleRemovedEvent(msg) {
    const role = JSON.parse(msg.data.role);

    dispatch({
        type: RoleTypes.ROLE_DELETED,
        data: role,
    });
}

function handleChannelSchemeUpdatedEvent(msg) {
    dispatch(getMyChannelMember(msg.broadcast.channel_id));
}

function handleRoleUpdatedEvent(msg) {
    const role = JSON.parse(msg.data.role);

    dispatch({
        type: RoleTypes.RECEIVED_ROLE,
        data: role,
    });
}

function handleChannelCreatedEvent(msg) {
    return async (myDispatch, myGetState) => {
        const channelId = msg.data.channel_id;
        const teamId = msg.data.team_id;
        const state = myGetState();

        if (getCurrentTeamId(state) === teamId) {
            let channel = getChannel(state, channelId);

            if (!channel) {
                await myDispatch(getChannelAndMyMember(channelId));

                channel = getChannel(myGetState(), channelId);
            }

            myDispatch(addChannelToInitialCategory({channel, setOnServer: false}));
        }
    };
}

function handleChannelDeletedEvent(msg) {
    const state = getState();
    const config = getConfig(state);
    const viewArchivedChannels = config.ExperimentalViewArchivedChannels === 'true';
    if (getCurrentChannelId(state) === msg.data.channel_id && !viewArchivedChannels) {
        const teamUrl = getCurrentRelativeTeamUrl(state);
        const currentTeamId = getCurrentTeamId(state);
        const redirectChannel = getRedirectChannelNameForTeam(state, currentTeamId);
        browserHistory.push(teamUrl + '/channels/' + redirectChannel);
    }

    dispatch({
        type: ChannelTypes.RECEIVED_CHANNEL_DELETED,
        data: {
            id: msg.data.channel_id,
            team_id: msg.broadcast.team_id,
            deleteAt: msg.data.delete_at,
            viewArchivedChannels,
        },
    });
}

function handleChannelUnarchivedEvent(msg) {
    const state = getState();
    const config = getConfig(state);
    const viewArchivedChannels = config.ExperimentalViewArchivedChannels === 'true';

    dispatch({
        type: ChannelTypes.RECEIVED_CHANNEL_UNARCHIVED,
        data: {id: msg.data.channel_id, team_id: msg.broadcast.team_id, viewArchivedChannels},
    });
}

function handlePreferenceChangedEvent(msg) {
    dispatch(handlePreferenceChangedForSidebar(msg));
    const preference = JSON.parse(msg.data.preference);
    dispatch({type: PreferenceTypes.RECEIVED_PREFERENCES, data: [preference]});
}

function handlePreferencesChangedEvent(msg) {
    dispatch(handlePreferencesChangedForSidebar(msg));
    const preferences = JSON.parse(msg.data.preferences);
    dispatch({type: PreferenceTypes.RECEIVED_PREFERENCES, data: preferences});
}

function handlePreferencesDeletedEvent(msg) {
    const preferences = JSON.parse(msg.data.preferences);
    dispatch({type: PreferenceTypes.DELETED_PREFERENCES, data: preferences});
}

function handleDmUserStatusesUpdate(userId, state) {
    const dmUsers = getDmUsers(state);
    const currentUserId = getCurrentUserId(state);

    if (currentUserId !== userId && dmUsers.includes(userId)) {
        const status = getStatusForUserId(state, userId);

        if (status !== General.ONLINE) {
            dispatch(getStatusesByIds([userId]));
        }
    }
}

export function handleUserTypingEvent(msg) {
    return async (doDispatch, doGetState) => {
        const state = doGetState();

        if (
            isPerformanceDebuggingEnabled(state) &&
            getBool(state, Preferences.CATEGORY_PERFORMANCE_DEBUGGING, Preferences.NAME_DISABLE_TYPING_MESSAGES)
        ) {
            return;
        }

        const userId = msg.data.user_id;
        const channelId = msg.broadcast.channel_id;
        const parentPostId = msg.data.parent_id;

        handleDmUserStatusesUpdate(userId, state);

        const currentChannelId = getCurrentChannelId(state);
        const selectedPostId = getSelectedPostId(state);
        const selectedThreadId = getSelectedThreadId(state);

        const isSameChannel = channelId === currentChannelId;
        const isSameSelectedPost = Boolean(selectedPostId) && parentPostId === selectedPostId;
        const isSameSelectedThread = Boolean(selectedThreadId) && parentPostId === selectedThreadId;

        const shouldSkipTypingEvent = !isSameChannel && !isSameSelectedPost && !isSameSelectedThread;

        if (shouldSkipTypingEvent) {
            return;
        }

        const config = getConfig(state);
        const profiles = getUsers(state);

        const data = {
            id: channelId + parentPostId,
            userId,
            now: Date.now(),
        };

        if (!profiles[userId]) {
            await dispatch(fetchUsersByIds({userIds: [userId]}));
        }

        doDispatch({
            type: WebsocketEvents.TYPING,
            data,
        });

        setTimeout(() => {
            doDispatch({
                type: WebsocketEvents.STOP_TYPING,
                data,
            });
        }, parseInt(config.TimeBetweenUserTypingUpdatesMilliseconds, 10));
    };
}

export function handleStatusChangedEvent(msg) {
    const userId = msg.data.user_id;

    const state = getState();
    const existsUsers = getUsers(state);

    if (!existsUsers?.[userId]) {
        return;
    }

    return dispatch({
        type: UserTypes.RECEIVED_STATUSES,
        data: [{user_id: msg.data.user_id, status: msg.data.status}],
    });
}

function handleHelloEvent(msg) {
    setServerVersion(msg.data.server_version)(dispatch, getState);
}

function handleReactionAddedEvent(msg) {
    const reaction = JSON.parse(msg.data.reaction);

    dispatch(getCustomEmojiForReaction(reaction.emoji_name));

    dispatch({
        type: PostTypes.RECEIVED_REACTION,
        data: reaction,
    });

    if (msg.data.notify) {
        dispatch(sendReactionNotification({
            post_id: reaction.post_id,
            user_id: reaction.user_id,
            emoji_name: reaction.emoji_name,
            channel_display_name: msg.data.channel_display_name,
            channel_id: msg.data.channel_id,
            channel_name: msg.data.channel_name,
            channel_type: msg.data.channel_type,
            root_id: msg.data.root_id,
            sender_name: msg.data.sender_name,
        }));
    }
}

function handleAddEmoji(msg) {
    const data = JSON.parse(msg.data.emoji);

    dispatch({
        type: EmojiTypes.RECEIVED_CUSTOM_EMOJI,
        data,
    });
}

function handleReactionRemovedEvent(msg) {
    const reaction = JSON.parse(msg.data.reaction);

    dispatch({
        type: PostTypes.REACTION_DELETED,
        data: reaction,
    });
    dispatch(deleteActivityReaction({
        postId: reaction.post_id,
        emojiName: reaction.emoji_name,
        userId: reaction.user_id,
    }));
}

function handleChannelViewedEvent(msg) {
    // Useful for when multiple devices have the app open to different channels
    const state = getState();
    if (
        (!isAppFocused(state) || getCurrentChannelId(state) !== msg.data.channel_id) &&
        getCurrentUserId(state) === msg.broadcast.user_id
    ) {
        dispatch(markChannelAsRead(msg.data.channel_id, '', false));
    }
}

export function handlePluginEnabled(msg) {
    const manifest = msg.data.manifest;

    loadPlugin(manifest).catch((error) => {
        console.error(error.message); //eslint-disable-line no-console
    });
}

export function handlePluginDisabled(msg) {
    const manifest = msg.data.manifest;
    removePlugin(manifest);
}

async function handleUserRoleUpdated(msg) {
    const user = getState().entities.users.profiles[msg.data.user_id];

    if (user) {
        const roles = msg.data.roles;
        const newRoles = roles.split(' ');
        const demoted =
            user.roles.includes(Constants.PERMISSIONS_SYSTEM_ADMIN) &&
            !roles.includes(Constants.PERMISSIONS_SYSTEM_ADMIN);

        await dispatch(receivedUser({...user, roles}));

        dispatch(loadRolesIfNeeded(newRoles));

        if (demoted && global.location.pathname.startsWith('/admin_console')) {
            redirectUserToDefaultTeam();
        }
    }
}

function handleConfigChanged(msg) {
    dispatch({type: GeneralTypes.CLIENT_CONFIG_RECEIVED, data: msg.data.config});
}

function handleConfigTermsOfServiceIdChanged(msg) {
    dispatch({
        type: GeneralTypes.CLIENT_CONFIG_RECEIVED,
        data: {CustomTermsOfServiceId: msg.data.terms_of_service_id},
    });
}

function handleLicenseChanged(msg) {
    dispatch({type: GeneralTypes.CLIENT_LICENSE_RECEIVED, data: msg.data.license});
}

function handlePluginStatusesChangedEvent(msg) {
    dispatch({type: AdminTypes.RECEIVED_PLUGIN_STATUSES, data: msg.data.plugin_statuses});
}

function handleOpenDialogEvent(msg) {
    const data = (msg.data && msg.data.dialog) || {};
    const dialog = JSON.parse(data);

    dispatch({type: IntegrationTypes.RECEIVED_DIALOG, data: dialog});

    const currentTriggerId = getState().entities.integrations.dialogTriggerId;

    if (dialog.trigger_id !== currentTriggerId) {
        return;
    }

    dispatch(openModal({modalId: ModalIdentifiers.INTERACTIVE_DIALOG, dialogType: InteractiveDialog}));
}

export function handleGroupUpdatedEvent(msg) {
    const data = JSON.parse(msg.data.group);

    const isArchived = data.delete_at > 0;

    if (isArchived) {
        dispatch({
            type: GroupTypes.ARCHIVED_GROUP,
            id: data.id,
        });
    } else {
        dispatch({
            type: GroupTypes.RECEIVED_GROUP,
            data,
        });
    }
}

function handleGroupAddedMemberEvent(msg) {
    return (doDispatch, doGetState) => {
        const state = doGetState();
        const currentUserId = getCurrentUserId(state);
        const data = JSON.parse(msg.data.group_member);

        if (currentUserId === data.user_id) {
            doDispatch({
                type: GroupTypes.ADD_MY_GROUP,
                data,
                id: data.group_id,
            });
        }
    };
}

function handleGroupDeletedMemberEvent(msg) {
    return (doDispatch, doGetState) => {
        const state = doGetState();
        const currentUserId = getCurrentUserId(state);
        const data = JSON.parse(msg.data.group_member);

        if (currentUserId === data.user_id) {
            doDispatch({
                type: GroupTypes.REMOVE_MY_GROUP,
                data,
                id: data.group_id,
            });
        }
    };
}

function handleGroupAssociatedToTeamEvent(msg) {
    dispatch({
        type: GroupTypes.RECEIVED_GROUP_ASSOCIATED_TO_TEAM,
        data: {teamID: msg.broadcast.team_id, groups: [{id: msg.data.group_id}]},
    });
}

function handleGroupNotAssociatedToTeamEvent(msg) {
    dispatch({
        type: GroupTypes.RECEIVED_GROUP_NOT_ASSOCIATED_TO_TEAM,
        data: {teamID: msg.broadcast.team_id, groups: [{id: msg.data.group_id}]},
    });
}

function handleGroupAssociatedToChannelEvent(msg) {
    dispatch({
        type: GroupTypes.RECEIVED_GROUP_ASSOCIATED_TO_CHANNEL,
        data: {channelID: msg.broadcast.channel_id, groups: [{id: msg.data.group_id}]},
    });
}

function handleGroupNotAssociatedToChannelEvent(msg) {
    dispatch({
        type: GroupTypes.RECEIVED_GROUP_NOT_ASSOCIATED_TO_CHANNEL,
        data: {channelID: msg.broadcast.channel_id, groups: [{id: msg.data.group_id}]},
    });
}

function handleWarnMetricStatusReceivedEvent(msg) {
    var receivedData = JSON.parse(msg.data.warnMetricStatus);
    let bannerData;
    if (receivedData.id === WarnMetricTypes.SYSTEM_WARN_METRIC_NUMBER_OF_ACTIVE_USERS_500) {
        bannerData = AnnouncementBarMessages.WARN_METRIC_STATUS_NUMBER_OF_USERS;
    } else if (receivedData.id === WarnMetricTypes.SYSTEM_WARN_METRIC_NUMBER_OF_POSTS_2M) {
        bannerData = AnnouncementBarMessages.WARN_METRIC_STATUS_NUMBER_OF_POSTS;
    }

    dispatch(
        batchActions([
            {
                type: GeneralTypes.WARN_METRIC_STATUS_RECEIVED,
                data: receivedData,
            },
            {
                type: ActionTypes.SHOW_NOTICE,
                data: [bannerData],
            },
        ]),
    );
}

function handleWarnMetricStatusRemovedEvent(msg) {
    dispatch({type: GeneralTypes.WARN_METRIC_STATUS_REMOVED, data: {id: msg.data.warnMetricId}});
}

function handleSidebarCategoryDeleted(msg) {
    return (doDispatch, doGetState) => {
        const state = doGetState();

        if (msg.broadcast.team_id !== getCurrentTeamId(state)) {
            // The category will be removed when we switch teams.
            return;
        }

        // Fetch all categories since any channels that were in the deleted category were moved to other categories.
        doDispatch(fetchMyCategories(msg.broadcast.team_id));
    };
}

function handleSidebarCategoryOrderUpdated(msg) {
    return receivedCategoryOrder(msg.broadcast.team_id, msg.data.order);
}

export function handleUserActivationStatusChange() {
    return (doDispatch, doGetState) => {
        const state = doGetState();
        const license = getLicense(state);

        // This event is fired when a user first joins the server, so refresh analytics to see if we're now over the user limit
        if (license.Cloud === 'true') {
            if (isCurrentUserSystemAdmin(state)) {
                doDispatch(getStandardAnalytics());
            }
        }
    };
}

function handleCloudPaymentStatusUpdated() {
    return (doDispatch) => doDispatch(getCloudSubscription());
}

function handleRefreshAppsBindings() {
    return (doDispatch, doGetState) => {
        const state = doGetState();

        doDispatch(fetchAppBindings(getCurrentChannelId(state)));

        const siteURL = state.entities.general.config.SiteURL;
        const currentURL = window.location.href;
        let threadIdentifier;
        if (currentURL.startsWith(siteURL)) {
            const parts = currentURL.substr(siteURL.length + (siteURL.endsWith('/') ? 0 : 1)).split('/');
            if (parts.length === 3 && parts[1] === 'threads') {
                threadIdentifier = parts[2];
            }
        }
        const rhsPost = getSelectedPost(state);
        let selectedThread;
        if (threadIdentifier) {
            selectedThread = selectThreadById(state, threadIdentifier);
        }
        const rootID = threadIdentifier || rhsPost?.id;
        const channelID = selectedThread?.post?.channel_id || rhsPost?.channel_id;
        if (!rootID) {
            return {data: true};
        }

        doDispatch(fetchRHSAppsBindings(channelID));
        return {data: true};
    };
}

export function handleAppsPluginEnabled() {
    return {
        type: AppsTypes.APPS_PLUGIN_ENABLED,
    };
}

export function handleAppsPluginDisabled() {
    return {
        type: AppsTypes.APPS_PLUGIN_DISABLED,
    };
}

function handleFirstAdminVisitMarketplaceStatusReceivedEvent(msg) {
    const receivedData = JSON.parse(msg.data.firstAdminVisitMarketplaceStatus);
    dispatch({type: GeneralTypes.FIRST_ADMIN_VISIT_MARKETPLACE_STATUS_RECEIVED, data: receivedData});
}
