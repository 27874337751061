import type {Middleware} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import localforage from 'localforage';

import {draftsReducer, draftsReducerName, middlewares as draftsFeatureMiddlewares} from './drafts';
import type {DraftsReducerState} from './drafts';
import {sidebarForTeamLoadingSlice} from './sidebar';
import {channelPermissionsSlice, readOnlyChannelsSlice} from './channels';
import {deletePostsOnChannelSwitchMiddleware} from './posts/middlewares/delete_posts_on_channel_switch';
import {profilesSlice} from './profiles';
import {activitySlice} from './activity/slices/activity';
import {userThreadsSlice} from './threads/slices/user_threads';
import {userThreadsListenerMiddleware} from './threads/middlewares/user_threads_listener_middleware';
import {updatePresenceListenerMiddleware} from './websockets/middlewares/update_presence_middleware';
import type {OpenDesktopSettingsState} from './open_desktop';
import {openDesktopSettingsSlice} from './open_desktop';

import type {ProfilesState} from './profiles';
import type {ReadOnlyChannelsState} from './channels';
import {appVisibilitySlice} from './app_activity/slices/app_visibility_slice';
import {fatalErrorsReducerName, fatalErrorsReducer, type FatalErrorsState} from './fatalErrors';

import type {ActivityState} from './activity/slices/activity';
import {termsOfServiceSlice} from './terms_of_service/slices/terms_of_service';

export const featuresReducers = {
    [fatalErrorsReducerName]: fatalErrorsReducer,
    [draftsReducerName]: draftsReducer,
    [sidebarForTeamLoadingSlice.name]: sidebarForTeamLoadingSlice.reducer,
    [channelPermissionsSlice.name]: channelPermissionsSlice.reducer,
    [readOnlyChannelsSlice.name]: readOnlyChannelsSlice.reducer,
    [profilesSlice.name]: profilesSlice.reducer,
    [appVisibilitySlice.name]: appVisibilitySlice.reducer,
    [activitySlice.name]: activitySlice.reducer,
    [userThreadsSlice.name]: userThreadsSlice.reducer,
    [termsOfServiceSlice.name]: termsOfServiceSlice.reducer,
    [openDesktopSettingsSlice.name]: persistReducer({key: openDesktopSettingsSlice.name, storage: localforage}, openDesktopSettingsSlice.reducer),
};

type SidebarForTeamLoadingState = ReturnType<typeof sidebarForTeamLoadingSlice['getInitialState']>;
type ChannelPermissionsState = ReturnType<typeof channelPermissionsSlice['getInitialState']>;
type AppVisibilityState = ReturnType<typeof appVisibilitySlice['getInitialState']>;
type UserThreadsState = ReturnType<typeof userThreadsSlice['getInitialState']>;
type TermsOfServiceState = ReturnType<typeof termsOfServiceSlice['getInitialState']>

export type FeaturesReducersState = {
    [fatalErrorsReducerName]: FatalErrorsState;
    [draftsReducerName]: DraftsReducerState;
    [sidebarForTeamLoadingSlice.name]: SidebarForTeamLoadingState;
    [channelPermissionsSlice.name]: ChannelPermissionsState;
    [readOnlyChannelsSlice.name]: ReadOnlyChannelsState;
    [profilesSlice.name]: ProfilesState;
    [appVisibilitySlice.name]: AppVisibilityState;
    [userThreadsSlice.name]: UserThreadsState;
    [activitySlice.name]: ActivityState;
    [termsOfServiceSlice.name]: TermsOfServiceState;
    [openDesktopSettingsSlice.name]: OpenDesktopSettingsState;
};

export const featuresMiddlewares: Middleware[] = [
    ...draftsFeatureMiddlewares,
    deletePostsOnChannelSwitchMiddleware,
    userThreadsListenerMiddleware,
    updatePresenceListenerMiddleware,
];
